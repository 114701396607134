<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Loader from "@/components/widgets/loader";
import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";

import store from "@/state/store";
import {apiBoardMethods} from "@/state/helpers";
import Swal from 'sweetalert2'
import moment from "moment";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import UploadAdapter from "@/helpers/upload-adapter";
import {getFileUrl, getImageRateUrl} from '@/helpers/api-config';
import VueEasyLightbox from "vue-easy-lightbox";
import {getFileSize} from "@/helpers/helpers";

/**
 * Editors component
 */

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger ms-2"
  },
  buttonsStyling: false
});
  
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: { Loader, Layout, ckeditor: CKEditor.component, VueEasyLightbox, PageHeader },
  data() {
    return {
      menuId: 400100,
      menuCode: this.$route.params.code,
      editor: ClassicEditor,

      data: {},
      formData: {
        uid : "",
        subject : "",
        content : " ",
      },
      formSubmitted: false,
      editorConfig: {
        toolbar: {
          items: [
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'subscript',
            'superscript',
            'removeFormat',
            '|',
            'horizontalLine',
            'link',
            'insertImage',
            'insertTable',
            'highlight',
            'blockQuote',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'sourceEditing',
          ],
          shouldNotGroupWhenFull: true
        },
        balloonToolbar: ['bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true
            }
          ]
        },
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        language: 'ko',
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
        },
        image: {
          resize: true,
          toolbar: ['imageTextAlternative', '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|', 'resizeImage', 'imageStyle:alignLeft', 'imageStyle:alignRight', 'imageStyle:inline', 'imageStyle:side']
        },
        extraPlugins: [
          function(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
              return new UploadAdapter(loader);
            }
          }
        ]
      },
      showThumbnail: false,

      totalFileSize: 0,
      uploadFiles: [],
      deleteFiles: [],

      showDropzoneDesc: true,
      isLoading: false,
      isUploading: false,
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    this.initData()
    this.loadData()
  },
  validations: function() {
    return {
      formData: {
        subject: {
          required: helpers.withMessage("제목을 입력해 주세요.", required),
        },
        content: {
          required: helpers.withMessage("내용을 입력해 주세요.", required)
        },
      }
    }
  },
  methods: {
    getFileSize,
    ...apiBoardMethods,

    initData() {
      let code = this.$route.params.code

      if(code === "event"){
        this.formData.startDate = new Date()
        this.formData.endDate = new Date()
      }
    },
    // 데이터 로딩
    loadData() {
      //uid있으면 데이터 불러와서 셋팅
      let {code, uid} = this.$route.params
      
      if(uid){
        store.dispatch("apiBoards/create", { code, uid }).then((result) => {
          this.data = result.data.data

          this.formData.uid = this.data.uid
          this.formData.subject = this.data.subject
          this.formData.content = this.data.content.trim().replace(/\r\n/g, "<br />");
          this.formData.fixed = Boolean(this.data.is_fixed)
          if(this.data.events){
            //이벤트일경우 추가로...
            this.formData.state = Boolean(this.data.events.state)
            this.formData.startDate = new Date(this.data.events.start_date)
            this.formData.endDate = new Date(this.data.events.end_date)
          }
          this.formData.delImage = false;

          this.data.files = {};
          this.data.image = null;
          this.data.images = '';
          this.data.thumbnails = '';
          if (this.data.assets) {
            this.data.assets.forEach((row, index) => {
              if (row.type == 'thumb') {
                this.data.image = row;
                this.data.images = getFileUrl(row.uid);
                this.data.thumbnails = getImageRateUrl("h100", row.uid);
              } else {
                this.data.files[index] = row;
                this.totalFileSize += row.asset.size;
              }
            });
            if (this.totalFileSize) {
              this.showDropzoneDesc = false;
            }
          }
        })
      }
    },

    // 게시글 저장
    checkSave() {
      Swal.fire({
        text: this.data.uid ? "게시글을 수정하시겠습니까?" : "게시글을 등록하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니요"
      }).then(result => {
        if (result.value) {
          this.saveData();
        }
      });
    },

    // 게시글 저장 서버요청
    saveData() {
      store.dispatch('notification/clear')

      // stop here if form is invalid
      this.v$.$touch();

      this.formSubmitted = true;
      this.isUploading = true;

      if (this.v$.formData.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          "uid": this.formData.uid,
          "code": this.menuCode,
          "subject": this.formData.subject,
          "content": this.formData.content,
          "delFiles": this.deleteFiles,
          "files": this.uploadFiles
        }

        if (this.menuCode === "notice") {
          params.is_fixed = (this.formData.fixed === true)
        }

        if (this.menuCode === "event") {
          params.start_date = moment(this.formData.startDate).format('YYYY-MM-DD');
          params.end_date = moment(this.formData.endDate).format('YYYY-MM-DD');
          params.state = (this.formData.state === true) ? 1 : 0;
          params.delImage = (this.formData.delImage === true) ? 1 : 0;
          params.image = this.formData.image;
        }

        store.dispatch("apiBoards/store", params).then((result) => {
          this.formSubmitted = false;
          this.isUploading = false;
          Swal.fire(result.message, "", result.success ? "success" : "error");
          // window.location.reload()
          this.$router.push('/bbs/'+this.menuCode)
        }, () => {
          this.formSubmitted = false;
          this.isUploading = false;
        });
      }
    },

    //게시글 삭제
    deleteData() {
      swalWithBootstrapButtons
        .fire({
          title: "게시글을 삭제 하겠습니까?",
          text: "삭제 후 되돌릴 수 없습니다.",
          icon: "warning",
          confirmButtonText: "삭제",
          cancelButtonText: "취소",
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            this.requestDeleteData()
          } else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
            console.log('cancel')
          }
        });
    },

    // 게시글 삭제 서버요청
    requestDeleteData() {
      let {code, uid} = this.$route.params
      let params = {
        'uid' : uid
      }
      if(uid){
        this.formSubmitted = true
        store.dispatch("apiBoards/del", { code, params }).then((result) => {
          Swal.fire(result.message, "", result.success ? "success" : "error");
          this.formSubmitted = false
          this.$router.push('/bbs/'+this.menuCode)
        }, () => {
          this.formSubmitted = false
        });
      }
    },

    showImage() {
      this.showThumbnail = true;

    },

    hideImage() {
      this.showThumbnail = false;
    },

    changeImage(e) {
      const file = e.target.files;
      let message = '';
      if (file[0].type.indexOf('image') < 0) {
        message = '이미지 파일만 업로드 가능합니다.';
      }

      if (!message) {
        this.formData.image = file[0]
      } else {
        Swal.fire(message, "", "error");
      }
    },

    dropzoneOn() {
      let dropzone = document.querySelector("#dropzone");
      dropzone.classList.remove('dropzone-off');
      dropzone.classList.add('dropzone-on');
    },

    dropzoneOff() {
      let dropzone = document.querySelector("#dropzone");
      dropzone.classList.remove('dropzone-on');
      dropzone.classList.add('dropzone-off');
    },

    fileDrop(e) {
      this.dropzoneOff();
      let files = e.dataTransfer.files;
      if (files != null) {
        if (files.length < 1) {
          Swal.fire('파일이 선택되지 않았습니다.', "", "error");
          return false;
        } else {
          this.selectFiles(files);
        }
      } else {
        Swal.fire('ERROR!!', "", "error");
      }
    },

    dropzoneSelect(e) {
      this.selectFiles(e.target.files);
    },

    dropzoneClick(e) {
      let target = e.target;
      if (target.id == 'dropzone' || target.parentElement.id == 'dropzone-desc' || target.id == 'dropzone-desc' || target.id == 'dropzone-list' || target.id == 'file-list') {
        document.querySelector("#selectFiles").click();
      }
    },

    selectFiles(files) {
      if (files == null) {
        Swal.fire('파일이 선택되지 않았습니다.', "", "error");
        return false;
      }

      this.isLoading = true;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.size <= 0) {
          Swal.fire('비어 있는 파일입니다.', "", "error");
        } else if (this.isExecute(file.name)) {
          Swal.fire('업로드가 허용되지 않는 파일입니다.', "", "error");
        } else {
          this.uploadFiles.push(file);
          this.totalFileSize += file.size;

          this.showDropzoneDesc = false;
        }
      }
      this.isLoading = false;
    },

    isExecute(fileName) {
      let extExecutes = ["php","php3","php4","phps","phtm","phtml","htm","html","sh","ph","inc","cgi","pl","tpl","exe","com","bat","dll","cpl","asp","aspx","vbs","java","jsp"];
      let fileNames = fileName.split(".");
      let fileExtension = fileNames[fileNames.length - 1].toLowerCase();

      return extExecutes.includes(fileExtension);
    },

    filePath(uid) {
      return getFileUrl(uid);
    },

    deleteFile(e) {
      let fileNode = e.target.parentElement;
      this.deleteFiles.push(fileNode.getAttribute("data-uid"));
      this.totalFileSize -= parseInt(fileNode.getAttribute("data-size"));

      fileNode.remove();

      this.toggleDesc();
    },

    deleteNewFile(file, fIndex) {
      this.totalFileSize -= parseInt(file.size);

      this.isLoading = true;
      let files = this.uploadFiles;
      delete files[fIndex];

      this.uploadFiles = [];
      files.forEach(row => {
        this.uploadFiles.push(row);
      });
      this.isLoading = false;

      this.toggleDesc();
    },

    toggleDesc() {
      let olds = document.querySelectorAll(".old-file");
      this.showDropzoneDesc = !olds.length && !this.uploadFiles.length;
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    noneImage() {
      return require('@/assets/images/no-image.png')
    },
    setFileSize() {
      return getFileSize(this.totalFileSize);
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form  @submit.prevent="checkSave" enctype="multipart/form-data">
                <b-form-group
                    class="mb-3"
                    label="제목"
                    label-for="subject"
                    :invalid-feedback="v$.formData.subject.$error ? v$.formData.subject.$errors[0].$message : null"
                    :state="!v$.formData.subject.$error"
                >
                    <b-form-input
                    type="text"
                    v-model="formData.subject"
                    :state="!v$.formData.subject.$error"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>

                <div class="form-check mb-3" v-if="menuCode == 'notice'">
                  <input
                    id="is_fixed"
                    class="form-check-input"
                    type="checkbox"
                    v-model="formData.fixed"
                  />
                  <label class="form-check-label" for="is_fixed">
                    공지고정
                  </label>
                </div>

                <div class="mb-3" v-if="menuCode == 'event'">
                  <div class="row mb-3">
                    <label>이벤트 기간</label>
                    <div class="col-lg-2 mb-2 mb-lg-0 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="formData.startDate"
                          value-type="date"
                          placeholder="시작일"
                      ></date-picker>
                    </div>
                    <div class="col-lg-2 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="formData.endDate"
                          value-type="date"
                          placeholder="종료일"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="form-check">
                    <input
                        id="state"
                        class="form-check-input"
                        type="checkbox"
                        v-model="formData.state"
                    />
                    <label class="form-check-label" for="state">
                      이벤트 마감
                    </label>
                  </div>
                </div>

              <b-form-group
                  class="mb-3"
                  id="password-group"
                  label="내용"
                  label-for="content"
                  :invalid-feedback="v$.formData.content.$error ? v$.formData.content.$errors[0].$message : null"
                  :state="!v$.formData.content.$error"
              >
                <ckeditor
                    v-model.lazy="formData.content"
                    :editor="editor" :config="editorConfig"
                    :state="!v$.formData.content.$error"
                ></ckeditor>
              </b-form-group>

                <div class="mb-3" v-if="menuCode == 'event'">
                  <div class="mb-3">
                    <label for="thumb" class="form-label">목록 이미지</label>
                    <div class="input-group">
                      <input class="form-control" type="file" accept="image/*" id="image" @change="changeImage" />
                    </div>
                    <div v-if="data.image">
                      <a href="javascript:;" @click="() => showImage()"><i style="font-size:1.1rem;margin-bottom:2px;vertical-align:middle;" class="bx bx-image-alt"></i> {{ data.image.asset.orgin_name }} ({{ getFileSize(data.image.asset.size) }})</a>
                      <vue-easy-lightbox
                          moveDisabled
                          :visible="showThumbnail"
                          :imgs="data.images"
                          @hide="hideImage"
                      ></vue-easy-lightbox>
                      <div class="d-inline-block ms-2">
                        <span class="form-check mt-2">
                          <input
                              id="delImage"
                              class="form-check-input"
                              type="checkbox"
                              v-model="formData.delImage"
                          />
                          <label class="form-check-label d-block" for="delImage">삭제</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label>첨부파일</label>
                  <span class="file-size ms-1">(<em>{{ setFileSize }}</em>)</span>
                  <input type="file" name="selectFiles[]" id="selectFiles" class="dropzone-file" multiple="multiple" @change="dropzoneSelect" />
                  <div id="dropzone"
                       class="dropzone-area"
                       @dragenter.prevent="dropzoneOn"
                       @dragleave.prevent="dropzoneOff"
                       @dragover.prevent="dropzoneOn"
                       @drop.prevent="fileDrop"
                       @click="dropzoneClick"
                  >
                    <Loader :loading="isLoading" class="position-absolute base-list-loader" style="z-index:9999;"></Loader>
                    <div id="dropzone-desc" class="dropzone-desc" v-show="showDropzoneDesc">
                      <i class="display-6 text-muted bx bxs-cloud-upload"></i>
                      <h6>첨부파일을 업로드하려면 클릭하거나<br />이 곳에 파일을 드래그 하세요.</h6>
                    </div>
                    <div id="dropzone-list" class="dropzone-list" v-show="!showDropzoneDesc">
                      <ul id="file-list" class="file-list">
                        <li class="old-file" v-for="(row, index) in data.files" :key="index" :data-uid="row.uid" :data-size="row.asset.size">
                          <i class="fas fa-trash" title="삭제" @click.prevent="deleteFile"></i>
                          <a :href="filePath(row.uid)" class="file-item cut-text">{{ row.asset.orgin_name }} ({{ getFileSize(row.asset.size) }})</a>
                        </li>
                        <li class="new-file" v-for="(row, index) in uploadFiles" :key="index">
                          <i class="fas fa-trash" title="삭제" @click="deleteNewFile(row, index)"></i>
                          <span class="file-item cut-text">{{ row.name }} ({{ getFileSize(row.size) }})</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                    <b-button variant="primary" type="submit" :disabled="formSubmitted">
                      <span v-if="formData.uid">수정</span>
                      <span v-else>저장</span>
                    </b-button>
                    <b-button v-if="formData.uid" variant="danger" class="ms-3" :disabled="formSubmitted" @click="deleteData()">삭제</b-button>
                </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.dropzone-area {
  position: relative;
  display: block;
  width: 100%;
  min-height: 150px;
  padding: 0;
  color: #495057;
  background-color: #ffffff;
  border: 2px dotted #ced4da;
  border-radius: 4px;
  cursor: pointer;
}

.dropzone-desc {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.dropzone-desc h6 {
  line-height: 1.4em;
}

.ck-content p {
  margin-bottom: 0.2rem;
}

.dropzone-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  font-size: 1px;
  width: 1px;
  height: 1px;
}

.dropzone-list {
  position: relative;
  z-index: 999;
}

.file-size {
  display: inline-block;
}

.file-size em {
  color: #f30;
  font-style: normal;
}

.file-list,
.file-list li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.file-list {
  padding: 0.7rem 0.9rem;
}

.file-list li {
  /*display: inline-block;*/
  position: relative;
  white-space: nowrap;
  border-radius: 0.3rem;
  background: #f5f5f5;
  border: 1px solid #ddd;
  padding: 0.3rem 1rem 0.1rem 0.8rem;
  margin: 0.3rem 0;
  cursor: default;
}

.file-list li.new-file {
  background: #ffefee;
}

.file-list li span,
.file-list li a {
  display: inline-block;
  color: #333;
  max-width: 90%;
}

.file-list li a:hover {
  color: #d8640e;
}

.file-list i {
  color: #dd792b;
  cursor: pointer;
  margin-right: 0.4rem;
  position: absolute;
  top: 0.6rem;
  right: 0.4rem;
}

.vel-btns-wrapper .btn__close {
  top: 35px;
  right: calc(50% - 10px);
}
</style>