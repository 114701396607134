import { computed, onMounted, onUnmounted, ref } from "vue"
import moment from "moment";


export function formatPhone(phone) {
    phone = phone.replace(/[^0-9]/, "");
    var length = phone.length;

    switch(length){
        case 11 :
            return phone.replace(/([0-9]{3})([0-9]{4})([0-9]{4})/, "$1-$2-$3");
        case 10:
            return phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "$1-$2-$3");
        case 9:
            return phone.replace(/([0-9]{2})([0-9]{3})([0-9]{4})/, "$1-$2-$3");
    }
    return phone;
}
export function numberFormat(value) {
    return Number(Number(value).toFixed(0)).toLocaleString('ko-KR');
}
export const useBreakpoints = () => {
    let windowWidth = ref(window.innerWidth)

    const onWidthChange = () => windowWidth.value = window.innerWidth
    onMounted(() => window.addEventListener('resize', onWidthChange))
    onUnmounted(() => window.removeEventListener('resize', onWidthChange))

    const type = computed(() => {
        if (windowWidth.value < 550) return 'xs'
        if (windowWidth.value >= 550 && windowWidth.value < 1200) return 'md'
        if (windowWidth.value >= 1200) return 'lg'
        return null; // This is an unreachable line, simply to keep eslint happy.
    })

    const width = computed(() => windowWidth.value)

    return { width, type }
}

export function toHHMM(value) {
    var sec_num = parseInt(value, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return ((hours === '00')?'':hours+':')+minutes+'분';
}

// 처리상태
export function getStatusName(status) {
    let str = '접수';
    if (status === 'Approve') {
        str = '승인';
    } else if (status === 'Cancel') {
        str = '취소';
    } else if (status === 'Complete') {
        str = '완료';
    }
    return str;
}

export function setStatusCss(status) {
    let str = 'text-dark';
    if (status === 'Approve') {
        str = 'text-success';
    } else if (status === 'Cancel') {
        str = 'text-danger';
    } else if (status === 'Complete') {
        str = 'text-primary';
    }
    return str;
}

export function ckEditorEnterBreaks(editor) {
    editor.editing.view.document.on('enter', (evt, data) => {
        //shift + enter creates a br tag
        editor.execute('shiftEnter');
        //cancel enter event to prevent p tag
        data.preventDefault();
        evt.stop();
    }, {priority: 'high' });
}

export function getFileSize(bytes) {
    let kb = bytes / 1024;
    let mb = kb / 1024;
    let gb = mb / 1024;

    let str = "";
    if (bytes >= 1024 * 1024 * 1024) {
        str = gb.toFixed(2) + "GB";
    } else if (bytes >= 1024 * 1024) {
        str = mb.toFixed(2) + "MB";
    } else if (bytes >= 1024) {
        str = parseInt(kb) + "KB";
    } else if (bytes <= 0) {
        str = bytes;
    } else {
        str = parseInt(bytes) + "B";
    }

    return str;
}

export function dateFormat(date, format) {
    let dates = moment(date);
    return dates.format(format);
}

export function getUserName(data) {
    let userName = '-';
    if (data.user_name) {
        userName = data.user_name;
    } else if (data.user && data.user.name) {
        userName = data.user.name;
    }
    return userName;
}

export function getUserPhone(data) {
    let userPhone = '-';
    if (data.user_phone) {
        userPhone = data.user_phone;
    } else if (data.user && data.user.phone) {
        if (data.user.status == 'SignOut') {
            userPhone = data.user.phone.substring(0,10);
        } else {
            userPhone = data.user.phone;
        }
    }
    return userPhone;
}
